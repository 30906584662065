/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {bindActionCreators} from "redux";

import * as W17F1025Actions from "../../../actions/w17/W17F1025/W17F1025-actions";
import * as userActions from "../../../actions/user-actions";

import PopupView from "../../popup/popup-view";
import {Tabs, Tab} from 'react-bootstrap';
import {Column} from 'devextreme-react/data-grid';
import {Loading} from "../../common/loading/loading";
import GridContainer from "../../common/grid-container/grid-container";
import Config from "../../../config";
import Link from '../../common/link/link';
import GridActionBar from "../../common/action-bar/grid-action-bar";
import EventTracking from "../../common/tracking/event";
import notify from 'devextreme/ui/notify';

import W18F1019Pop from "../../W18/W18F1019"
import W18F1023 from "../../W18/W18F1023";
import W18F1026 from "../../W18/W18F1026";
import PageContainer from "../../common/page-container/page-container";
import ButtonExport from '../../common/button/button-export';
const TYPE_LIST = ['Customer', 'Partner', 'Competitor']
class W17F1025Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 15,
            tabKey: 1,
            type: '',
            showAddNewPopup: false,
            iPermission: 0,
            showW18F1019: false,
            companyIDW18F1019: '',
            companyNameW18F1019: '',
            CompanyIDW19F1026: '',
            CompanyStatusNameW18F1026: '',
            companyNameW18F1026: '',
            iPermissionShare: 0,
            showPopupW18F1023: false,

        };

        this.timer = null;
        this.timerFocus = null;
        this.filter = {
            skip: 0,
            limit: 15,
            search: '',
            CompanyStatus: '',
            UseFilter: 0,
            CompanyTypeID:"",
        };
        this.FormID = "W17F1025"
        this.transTypeID ="ExportQuotation"


    }

    loadPermission = async () => {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D17F1010", "D17F0003", "D17F1012"], (arrPer) => {
            if (arrPer !== 0) {
                const iPerD17F1010 = arrPer.filter(data => data.ScreenID === 'D17F1010');
                const iPerD17F0003 = arrPer.filter(data => data.ScreenID === 'D17F0003');
                const iPerD17T1012 = arrPer.filter(data => data.ScreenID === 'D17F1012');
                this.setState({
                    iPermission: iPerD17F1010 && iPerD17F1010.length > 0 && iPerD17F1010[0] ? iPerD17F1010[0].Permission : 0,
                    iPermissionHistory: iPerD17F0003 && iPerD17F0003.length > 0 && iPerD17F0003[0] ? iPerD17F0003[0].Permission : 0,
                    iPermissionShare: iPerD17T1012 && iPerD17T1012.length > 0 && iPerD17T1012[0] ? iPerD17T1012[0].Permission : 0,

                });
            }
        });
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new URL(window.location.href);
        // let value = url.searchParams.get(name)
        let state = location?.state || {};
        const type = state?.type || url.searchParams.get("type") || "Customer";
        return {
            ...state,
            type: type
        };
    };

    componentWillMount() {
        const type = this.getURLQuery('type');
        if (!type || !TYPE_LIST.includes(type)) {
            browserHistory.push(Config.env.url + '/W17F1025?type=Customer');
        }

    }


    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.props.W17F1025Actions.clearW17F1025();
        const type = this.getURLQuery("type")
        if (!type) {
            return
        }
        this.setState({type},()=>this.loadData(this.loadFilter))


    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
        if (this.timerFocus) clearTimeout(this.timerFocus);
    }

    loadData = (cb) => {

        const TypeID = this.state.type;
        const { search: StrSearch ="", skip, limit, CompanyStatus, UseFilter,CompanyTypeID}=this.filter || {}
        if (!TypeID || !TYPE_LIST.includes(TypeID)) return false;

        this.setState({isSubmit: false, message: '', loading: true});
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({isSubmit: true, message: '', loading: false});
        }, 30000);

        const params = {
            FormID:"W17F1025",
            Language: Config.language || '84',
            TypeID,
            StrSearch,
            skip,
            limit,
            CompanyTypeID,
            CompanyStatus,
            UseFilter,
        };

        this.props.W17F1025Actions.loadW17F1025fromStore(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data) {
                let {dataFilter =[]} = this.state
                dataFilter = dataFilter.map(item => {
                    let Num = data.addition[item.ItemID]
                    let CompanyStatus =Num ? `${item.CompanyStatus_Org || ""} (${Num})` : (item.CompanyStatus_Org || "")
                    return {
                        ...item,
                        CompanyStatus,
                        Num,
                    }
                })
                this.setState({loading: false,dataFilter});

                cb&&cb()
            }
        });
    }

    loadFilter = () => {
        const uName = Config.profile.UserID || '';
        const paramsFilter = {
            "UserID": uName,
        };
        this.props.W17F1025Actions.loadFilter(paramsFilter, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data) {
                data = data.map(item => {
                    let CompanyStatus_Org = item.CompanyStatus
                    let Num = this.props.loadW17F1025Store && this.props.loadW17F1025Store.addition &&
                                    this.props.loadW17F1025Store.addition[item.ItemID]
                    let CompanyStatus =Num ? `${CompanyStatus_Org} (${Num})` :CompanyStatus_Org
                    return {
                        ...item,
                        CompanyStatus_Org,
                        CompanyStatus,
                        Num,
                    }
                })
                this.setState({
                    dataFilter: data,
                });
                if (this.timerFocus) clearTimeout(this.timerFocus);
                this.timerFocus = setTimeout(() => {
                    let button = document.getElementsByClassName("filter-bar")[0];
                    button = button ? button.getElementsByTagName('button')[0] :false;
                    if (button) {
                        button.focus();
                    }
                }, 300);

                //this.setState({loading: false});
                // Set focus when first time loading list
            }
        });
    }
    _confirmDelete = (param) => {
        this.setState({isSubmit: false, message: '', loading: true});
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({isSubmit: true, message: '', loading: false});
        }, 30000);

        this.props.W17F1025Actions.delW17F1025(param,(error,data)=>{
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
            this.loadData();
        })
    }
    onDelete = (e) => {
        const {CompanyID, CompanyTypeID, CompanyStatus} = (e && e.row && e.row.data) || {}
        const {skip, limit, UseFilter} = this.filter || {}
        const params = {
            Language: Config.language || "84",
            CompanyID,
            CompanyTypeID,
            skip,
            limit,
            CompanyStatus,
            UseFilter,
        }
        this.setState({isSubmit: false, message: '', loading: true});
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({isSubmit: true, message: '', loading: false});
        }, 30000);
        this.props.W17F1025Actions.checkDel(params, (error,data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data.Status === 0) {
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                this.loadData();
                return false
            }
            if (data.Status === 1) {
                if (data.MsgAsk === 1) {
                    Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                        this._confirmDelete({code:data.code})
                    },()=>{
                        this.setState({isSubmit: false, message: data.Message, loading: false});
                        Config.popup.show("INFO", data.Message)
                    });
                    return false
                }
                this.setState({isSubmit: false, message: data.Message, loading: false});
                Config.popup.show("INFO", data.Message);


            }

        });

    }

    onDetail = (e) => {
        const type = this.getURLQuery("type");
        const {CompanyID, CompanyTypeID, Permission } = (e && e.data) || {}

        if (!CompanyID) {
            return null;
        }
        return {
            pathname: Config.getRootPath() + 'W17F1026',
            state: {mode: 'view',type, Permission, CompanyID, CompanyTypeID,}}

    };
    _onEdit = (param) => {
        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F1026',
            state:param
        })
    }
    onEdit(e) {

        const {CompanyID, CompanyTypeID/* ="TN" */, CompanyStatus, Permission} = (e && e.row && e.row.data) || {}
        const {skip, limit, UseFilter} = this.filter || {}
        const type = this.getURLQuery("type");
        if (!CompanyID) {
            return null;
        }
        const params = {
            Language: Config.language || "84",
            CompanyID,
            CompanyTypeID,
            skip,
            limit,
            CompanyStatus,
            UseFilter,
        }
        this.setState({isSubmit: false, message: '', loading: true});
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({isSubmit: true, message: '', loading: false});
        }, 30000);
        this.props.W17F1025Actions.checkEdit(params, (error,data) => {

            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data.Status === 0) {
                return this._onEdit({mode: 'edit',type, Permission, CompanyID, CompanyTypeID,})
            }
            if (data.Status === 1) {
                if (data.MsgAsk === 1) {
                    Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay"), () => {
                        this._onEdit({mode: 'edit',type, Permission, CompanyID, CompanyTypeID,})
                    },()=>{
                        this.setState({isSubmit: false, message: data.Message, loading: false});
                        Config.popup.show("INFO", data.Message)
                    });
                }
                if (data.MsgAsk === 0) {
                    this.setState({isSubmit: false, message: data.Message, loading: false});
                    Config.popup.show("INFO", data.Message);
                }

            }

        });

    }



    onAddNew() {
        const type = this.getURLQuery('type');
        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F1026',
            state: {mode: 'add',type,}
        })
    }



    onTransfer(e) {
        const rowTransfer = [{
            CompanyID: e.data.CompanyID,
            CompanyStatus: e.data.CompanyStatus,
        }];
        this.setState({rowW18F1023: rowTransfer, showPopupW18F1023: true});
    }

    hideAddNewPopup() {
        this.setState({showAddNewPopup: false, showPopupW18F1023: false});
        this.loadData();
    }

    onChangeTab(tab) {
        this.setState({tabKey: tab});
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadData();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadData();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadData();
    }

    onFilter(option) {
        this.filter.skip = 0;
        this.filter.CompanyStatus = option.ItemID || '';
        this.loadData();
    }

    onExportCompany() {
       if (this.exportRef.getWrappedInstance().onClick) {
        this.exportRef.getWrappedInstance().onClick("0")
       }
    }

    onTransType() {
        const url = 'W18F1022';
        browserHistory.push(Config.env.url + '/' + url);
    }

    renderCompanyName = (data) => {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        /* eslint-disable */

        return (
            <Link getData={() => this.onDetail(data)}>{name}</Link>
        );
        /* eslint-enable */
    };


    renderAction(data) {
        let titleName = '';
        const type = this.state.type;
        let isPers = data.data;
        if (type === 'Customer') {
            titleName = Config.lang("CRM_Chia_se_khach_hang");
        } else if (type === 'Competitor') {
            titleName = Config.lang("CRM_Chia_se_doi_thu");
        } else {
            titleName = Config.lang("CRM_Chia_se_doi_tac");
        }
        if (!data) return null;
        return (
            <GridActionBar
                isPer={isPers.Permission}
                tooltipEdit={"Sửa"}
                className={isPers.Permission <= 2 ? 'isDisabled' : ""}
                classNameIs={isPers.Permission}
                onEditAction={() => {
                    this.onEdit(data)
                }}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(data)
                }}
                btnHamburger={[
                    {
                        name: titleName,
                        icon: 'fas fa-share-alt text-blue',
                        disabled: isPers.Permission < 4,
                        className: isPers.Permission < 4 ? 'isDisabled' : '',
                        onClick: () => {
                            if (isPers.Permission >= 4) {
                                const rowData = data.data;
                                this.setState({
                                    companyNameW18F1019: rowData.CompanyNameU,
                                    companyIDW18F1019: rowData.CompanyID
                                });
                                this.setState({showW18F1019: true});
                            }
                        }
                    },

                    {
                        name: Config.lang("CRM_Chuyen_chu_so_huu"),
                        icon: 'fas fa-dolly text-black',
                        disabled: isPers.Permission < 4,
                        className: isPers.Permission < 4 ? 'isDisabled' : '',
                        onClick: () => {
                            if (isPers.Permission >= 4) {
                                this.onTransfer(data)
                            }
                        }
                    },

                ]}
            />
        );
    }
    getURLQuery =(name) =>{
        const params = this.getInfo();
        let value = params?.[name] || "";
        if (name === "type" && value === "Customers") {
            value = "Customer"
        }
        return value
    };
    componentWillUpdate(nextProps, nextState) {
        if (this.state.iPermission <= 0) return;
        const type = this.getURLQuery("type")

        if (this.state.type !== type) {
            this.filter.search = '';
            if (document.querySelector('.toolbar-container input'))
                document.querySelector('.toolbar-container input').value = '';
            this.props.W17F1025Actions.clearW17F1025();
            this.setState({
                type: type,
            }, () => {

                this.loadData( this.loadFilter());
            });
        }
    }

    onSearchAdvance = () => {
        this.filter.UseFilter = 1;
        this.loadData();
    };

    onClearAdvanced = () => {
        this.filter.UseFilter = 0;
        this.loadData();
    };

    render() {
        if (this.state.iPermission <= 0) return null;
        let titleGird = '';
        let titleLable = '';
        const type = this.state.type;
        if (type === 'Customer') {
            titleGird = Config.lang("CRM_Danh_sach_khach_hang");
            titleLable = Config.lang("CRM_Ten_khach_hang");

        } else if (type === 'Competitor') {
            titleGird = Config.lang("CRM_Danh_sach_doi_thu");
            titleLable = Config.lang("CRM_Ten_doi_thu");

        } else {
            titleGird = Config.lang("CRM_Danh_sach_doi_tac");
            titleLable = Config.lang("CRM_Ten_doi_tac");

        }
        const {loadW17F1025Store} = this.props;
        const {companyIDW18F1019, companyNameW18F1019} = this.state;
        let dataSource = [];
        let totalItems = 0;
        if (loadW17F1025Store && loadW17F1025Store.rows && loadW17F1025Store.rows.length > 0) {
            loadW17F1025Store.rows.map(o => {
                const row = {
                    AddressLineU: o.AddressLineU,
                    CompanyID: o.CompanyID,
                    CompanyNameU: o.CompanyNameU,
                    CreateDate: o.CreateDate,
                    CustomerLevelName: o.CustomerLevelName,
                    DateOfBirth: o.DateOfBirth,
                    Email: o.Email,
                    NotesU: o.NotesU,
                    SalesPersonNameU: o.SalesPersonNameU,
                    TaxNo: o.TaxNo,
                    Telephone: o.Telephone,
                    CompanyStatus: o.CompanyStatus,
                    CompanyStatusName: o.CompanyStatusName,
                    Permission: o.Permission,
                    ProvinceName: o.ProvinceName,
                    DistrictName: o.DistrictName,
                    GroupSalesNameU: o.GroupSalesNameU,
                    ObjectID: o.ObjectID,
                    CompanyTypeID:o.CompanyTypeID
                };

                dataSource.push(row);
                return o;
            });
            totalItems=loadW17F1025Store.total

        }

        //const reportTypeID = "W17F1025"
        const paramExport = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || '84'},
            {type: "NVarChar", id: "StrSearch", value: this.filter.search},
            {type: "VarChar", id: "CompanyStatus", value: this.filter.CompanyStatus},
            {type: "Integer", id: "UseFilter", value: this.filter.UseFilter},
            {type: "NVarChar", id: "TypeID", value: `Export_${type}`},
            {type: "VARCHAR", id: "ReportTypeID", value: this.FormID},
            {type: "VARCHAR", id: "CompanyStatus", value: this.filter.CompanyStatus || ""}
        ];
        return (
            <PageContainer>
                    <ButtonExport
                        className = "hide"
                        ref = {ref => this.exportRef = ref}
                        reportTypeID={"W18F1010"}
                        FormID={this.FormID}
                        id={'slave1'}
                        param={paramExport}
                        hideButton={'pdf'}
                    />
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1025"} label={Config.profile.UserID}/>}
                {dataSource && <GridContainer
                    title={titleGird}
                    isPer={this.state.iPermission}
                    showAdvancedSearch={type === 'Customer'}
                    formID={'W17F1025'}
                    onAdvancedSearch={this.onSearchAdvance}
                    onClearAdvanced={this.onClearAdvanced}
                    dataSource={dataSource}
                    totalItems={totalItems}
                    height={Config.getHeightGrid()}
                    listPerPage={[15, 20, 30, 50]}
                    itemPerPage={this.filter.limit}
                    filterData={this.state.dataFilter}
                    columnResizingMode={'widget'}
                    buttonCRM={[
                        {
                            buttonName: Config.lang("CRM_Thao_tac"),
                            buttonType: 'dropdown',
                            icon: 'fas fa-wrench text-blue',
                            align: 'right',
                            disabled: false,
                            menuItem: [
                                {
                                    name: Config.lang("CRM_Danh_sach_khach_hang_dung_chung"),
                                    disabled: this.state.iPermission < 1,
                                    icon: 'fas fa-list',
                                    onClick: () => {
                                        browserHistory.push(Config.env.url + '/W18F1016')
                                    }
                                },
                                {
                                    name: Config.lang("CRM_Xuat_Excel"),
                                    icon: 'fas fa-file-excel text-green',
                                    onClick: () => {
                                        this.onExportCompany()
                                    }
                                },

                                {
                                    name: Config.lang("CRM_Lich_su_thay_doi"),
                                    icon: 'fas fa-external-link-square-alt text-darkcyan',
                                    onClick: () => {
                                        this.onTransType()
                                    }
                                }
                            ]
                        }]}
                    onAddNew={() => this.onAddNew()}
                    onFilter={(o) => {
                        this.onFilter(o)
                    }}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}>
                    <Column
                        cellRender={(data) => this.renderAction(data)}
                        dataField={'CompanyID'}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column

                        dataField={'CompanyID'}
                        caption={Config.lang("CRM_Ma_khach_hang")}
                        sortOrder={'asc'}
                        fixed={window.innerWidth > 600}
                        minWidth={150}
                    />
                    <Column
                        cellRender={(data) => this.renderCompanyName(data)}
                        dataField={'CompanyNameU'}
                        caption={titleLable}
                        sortOrder={'asc'}
                        fixed={window.innerWidth > 600}
                        minWidth={150}
                    />
                    <Column
                        dataField={'AddressLineU'}
                        caption={Config.lang("CRM_Dia_chi")}
                        width={450} />
                    <Column
                        dataField={'Telephone'}
                        caption={Config.lang("CRM_Dien_thoai")}
                        width={120}/>
                    <Column
                        dataField={'Email'}
                        width={200}
                        caption={'Email'}/>
                    <Column
                        dataField={'SalesPersonNameU'}
                        caption={Config.lang("CRM_NVKD")}/>
                    <Column
                        dataField={'GroupSalesNameU'}
                        width={220}
                        caption={Config.lang("CRM_Nhom_kinh_doanh")}/>
                    <Column
                        dataField={'CreateDate'}
                        caption={Config.lang("CRM_Ngay_tao")}
                        dataType={'date'}
                        format={'dd-MM-yyyy'}
                        alignment={'center'}
                        width={120}/>
                    <Column
                        dataField={'DateOfBirth'}
                        caption={Config.lang("CRM_Sinh_nhat")}
                        dataType={'date'}
                        format={'dd-MM-yyyy'}
                        alignment={'center'}
                        width={120}/>
                    <Column
                        dataField={'TaxNo'}
                        caption={Config.lang("CRM_Ma_so_thue")}
                        width={120}/>
                    <Column
                        dataField={'NotesU'}
                        caption={Config.lang("CRM_Mo_ta")}
                        width={300}/>
                    <Column
                        dataField={'CustomerLevelName'}
                        caption={Config.lang("CRM_Phan_loai")}
                        width={250}/>
                    <Column
                        dataField={'ProvinceName'}
                        caption={Config.lang("CRM_Tinh_Thanh_pho")}
                        sortOrder={'asc'}
                        width={250}
                    />
                    <Column
                        dataField={'DistrictName'}
                        caption={Config.lang("CRM_Quan_Huyen")}
                        sortOrder={'asc'}
                        width={250}
                    />

                </GridContainer>}
                {this.state.loading && <Loading/>}
                {this.state.showW18F1019 &&
                <W18F1019Pop companyID={companyIDW18F1019} companyName={companyNameW18F1019} onHide={() => {
                    this.setState({showW18F1019: false});
                    this.loadData();
                }}/>
                }
                {this.state.showAddNewPopup &&
                <PopupView
                    title={Config.lang("CRM_Chuc_nang_them_moi")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Tabs activeKey={this.state.tabKey} onSelect={(key) => this.onChangeTab(key)}
                          id="controlled-tab-example">
                        <Tab eventKey={1} title={Config.lang("CRM_Thong_tin_chung")}
                             style={{padding: 10, border: '1px solid #ddd', borderTop: 0}}>
                            <div>helo ca nha 1</div>
                        </Tab>
                        <Tab eventKey={2} title={Config.lang("CRM_Thong_tin_chung")}
                             style={{padding: 10, border: '1px solid #ddd', borderTop: 0}}>
                            <div>helo ca nha 2</div>
                        </Tab>
                    </Tabs>
                </PopupView>
                }

                {this.state.showPopupW18F1023 &&
                <PopupView
                    title={Config.lang("CRM_Co_cau_kinh_doanh")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <W18F1023 data={this.state.rowW18F1023} onHide={() => this.hideAddNewPopup()}/>
                </PopupView>
                }

                {this.state.showW18F1026 &&
                <PopupView
                    title={Config.lang("CRM_Chuyen_doi_trang_thai")}
                    show={true}
                    animation={true}
                    onHide={() => {
                        this.setState({showW18F1026: false});
                    }}>
                    <W18F1026 data={this.state.rowW18F1026}
                              onHide={() => {
                                  this.setState({showW18F1026: false});
                              }}/>
                </PopupView>
                }
            </PageContainer>
        )
    }
}

export default connect(state => ({
        dataFilter: state.w17f1025.dataFilter,
        delW17F1025: state.w17f1025.delW17F1025,
        loadW17F1025Store:state.w17f1025.loadW17F1025Store,
    }),
    (dispatch) => ({
        W17F1025Actions: bindActionCreators(W17F1025Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W17F1025Page);
